.table-items {
  > :not(caption) > * > * {
    padding: 0.75rem;
    border-bottom-width: 0px;
    border-top-width: 1px;
    //box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }
  .form-select {
    min-width: 60px;
    font-size: 12px;
  }
  //.item-line-single {
  //  border-top-width: 1px;
  //}
  .item-modifiers-line td {
    padding-top:0px;
    border-top-width: 0px;
  }
}
.cart {
  //background: #dee2e6;
  margin-bottom: 50px;
  .card-cart {
    background-color: transparent;
    border-width: 0px;
    border-width-bottom:1px;
    margin-bottom: 10px;
    border-radius: 0px;
    &.is-invalid {
      border-top:5px solid $color-red;
      > .alert {
        border-radius: 0px;
      }
    }
    border-bottom: 10px solid $color-border;
  }
  //.table-items {
  //  tr > td.cart-items-quantity{
  //    width:100px;
  //  }
  //}
  .cart-items-quantity{
    width:100px;
  }
  .cart-items-name {
    h4 {
      font-size:15px;
      margin-bottom: 10px;
    }
    small {

    }
    ul {
      padding-inline-start: 0px;
      margin-block-start:0px;
      margin-left:0px;
      list-style: none;
      margin-bottom: 5px;
      h5 {
        font-size:14px;
        font-weight: 500;
        margin-bottom: 0px;
      }
      li {
        font-size: 12px;
        ul {
          margin-left: 10px;
        }
      }
    }
  }
  .card-payment {
    .list-group {
      .list-group-item {
        cursor: pointer;
        border-radius: 0px;
        &:hover {
          border-bottom: 1px solid $color-accent;
        }
      }
    }
  }

  .banks {
    img {
      width: 100%;
    }
    margin-left:-5px;
    margin-right:-5px;
    .bank-item {
      width:50%;
      padding-right: 5px;
      padding-left: 5px;
      cursor: pointer;
      img {
        border: 2px solid $color-border;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      &:hover {
        img {
          border-color:$color-accent;
        }
      }
      &.active {
        img {
          border-color:$color-accent;
        }
      }
      margin-bottom:10px;
    }
  }
  .cart-summary {
    margin-bottom: $padding-lg;
  }
  .card-shipment {
    .card-shipment-type {
      margin-bottom: $padding-default;
    }
  }
  .cart-rules {
    margin-bottom: $padding-lg;
  }
  .cart-response {
    margin-bottom: $padding-default;
    text-align: center;
    h1 {
      font-size: 30px;
    }
    strong {
      font-size: 120%;
      color: $color-accent;
    }
  }
  iframe {
    height:400px;
    width:100%;
    border:none;
  }
  .btn-pick-date {
    text-align: left;
    h5 {
      margin-bottom: 0px;
    }
  }
}

.cart-promotions {
  .btn {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px){
  .cart {
    .cart-items {
      display: none;
    }
    .banks {
      .bank-item {
        width:25%;
      }
    }
  }
}

@media (min-width: 992px){
  .cart {
    .banks {
      .bank-item {
        width:20%;
      }
    }
  }
}

@media (min-width: 1200px){
  .cart {
    .banks {
      .bank-item {
        width:16.666667%;
      }
    }
  }
}