
a {
  color: $color-custom;
}
.btn-default:hover, .btn-default.active {
  background: $color-custom;
  color: #fff;
}
.btn-default.active:hover {
  border-color: rgba($color-custom, 0.7);
  background-color: rgba($color-custom, 0.7);
}
.btn-primary {
  border-color: $color-custom;
  background: $color-custom;
  &:hover, &.active, &.active:hover {
    color: #fff;
    border-color: rgba($color-custom, 0.7);
    background-color: rgba($color-custom, 0.7);
  }
  &.disabled, &:disabled{
    border-color: $color-custom;
    background: $color-custom;
  }
}

.btn-primary-light {
  color: $color-custom;
  border-color: rgba($color-custom, 0.2);
  //background: rgba($color-custom-rgb, 0.8);
  background-color: rgba($color-custom, 0.2);
  &:hover {
    color: #fff;
    border-color: rgba($color-custom, 0.5);
    background-color: rgba($color-custom, 0.5);
  }
}

.loader {
  border-top-color: $color-custom;
}
.modal-full .close:hover {
  background-color: $color-custom;
}

.cart .card {
  border-bottom-color: rgba($color-custom, 0.2);
}

.cover .cover-content .cover-content-center-content .badge-default{
  background-color: rgba($color-custom, 0.2);
}
.container-menu-picker .nav-link.active{
  background-color: rgba($color-custom, 0.2);
}
.menu .menu-items .card {
  //border-color: rgba($color-custom, 0.2);
}
.menu .menu-items .menu-item:hover .card, .menu .menu-items .menu-item .card:hover {
  border-color: $color-custom;
}
.nav-tabs-top .nav-item.active .nav-link {
  border-bottom-color: $color-custom;
}
.nav-tabs-top {
  box-shadow: rgba($color-custom, 0.2) 0px -3px 0px inset;
}