.form-payment-card {
  max-width: 500px;
  //margin:0 auto;
  .equal {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    .col {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 5px;
      padding-left: 5px;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    input {
      width:100%;
      //height: 38px;
      //color: hsl(0, 0, 20);
      //padding: 10px;
      //border-radius: 5px;
      //font-size: 15px;
      //outline: none!important;
      //border: 1px solid hsla(0, 0, 0, 0.3);
      //box-shadow: inset 0 1px 4px hsla(0, 0, 0, 0.2);
    }
  }

  .form-group select.form-control {
    width:auto;
  }

  .form-card-provider {
    text-align: center;
    span {
      display: block;
      margin-top:10px;
    }
    margin-bottom: 20px;
  }
}

/*--------------------
Checkout
--------------------*/
.checkout {
  //margin:0 auto;
  //margin: 110px auto 30px;
  position: relative;
  //width: 460px;
  background: white;
  //border-radius: 15px;
  //padding: 160px 45px 30px;
  //box-shadow: 0 10px 40px hsla(0, 0, 0, .1);
}

/*--------------------
Credit Card
--------------------*/
.credit-card-box {
  margin:0 auto;
  //perspective: 1000;
  //width: 400px;
  width: 100%;
  height: 200px;
  @media (min-width: 768px) {
    height: 280px;
  }
  //position: absolute;
  //top: -112px;
  //left: 50%;
  //transform: translateX(-50%);

  &:hover .flip,
  &.hover .flip {
    transform: rotateY(180deg);
  }

  .front,
  .back {
    width: 100%;
    //width: 400px;
    height: 180px;
    @media (min-width: 768px) {
      height: 250px;
    }
    border-radius: 15px;
    backface-visibility: hidden;
    background: linear-gradient(135deg, #bd6772, #53223f);
    position: absolute;
    color: #fff;
    font-family: Inconsolata;
    top: 0;
    left: 0;
    text-shadow: 0 1px 1px hsla(0, 0%, 0%, 0.3);
    box-shadow: 0 1px 6px hsla(0, 0%, 0%, 0.3);

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url('http://cdn.flaticon.com/svg/44/44386.svg') no-repeat center;
      background-size: cover;
      opacity: .05;
    }
  }

  .flip {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }

  .logo {
    position: absolute;
    top: 9px;
    right: 20px;
    width: 60px;

    svg {
      width: 100%;
      height: auto;
      fill: #fff;
      display: none;
      &.active {
        display: block;
      }
    }
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);

    .logo {
      top: 120px;
      @media (min-width: 768px) {
        top: 185px;
      }
      svg {
        &.active {
          display: block;
        }
      }
    }

  }

  .chip {
    position: absolute;
    width: 60px;
    height: 45px;
    top: 20px;
    left: 20px;
    background: linear-gradient(135deg, hsl(269,54%,87%) 0%,hsl(200,64%,89%) 44%,hsl(18,55%,94%) 100%);;
    border-radius: 8px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border: 4px solid hsla(0, 0%, 50%, .1);
      width: 80%;
      height: 70%;
      border-radius: 5px;
    }
  }

  .strip {
    background: linear-gradient(135deg, hsl(0, 0%, 25%), hsl(0, 0%, 10%));
    position: absolute;
    width: 100%;
    height: 40px;
    top: 20px;
    @media (min-width: 768px) {
      height: 50px;
      top: 30px;
    }
    left: 0;
  }

  .number {
    position: absolute;
    margin: 0 auto;
    top: 75px;
    left: 19px;
    font-size: 30px;
    @media (min-width: 768px) {
      top: 103px;
      font-size: 38px;
    }
  }

  label {
    font-size: 10px;
    letter-spacing: 1px;
    text-shadow: none;
    text-transform: uppercase;
    font-weight: normal;
    opacity: 0.5;
    display: block;
    margin-bottom: 0px;
    @media (min-width: 768px) {
      margin-bottom: 3px;
    }
  }

  .card-holder,
  .card-expiration-date {
    position: absolute;
    margin: 0 auto;
    top: 125px;
    @media (min-width: 768px) {
      top: 180px;
    }
    left: 19px;
    font-size: 19px;
    text-transform: capitalize;
  }

  .card-expiration-date {
    text-align: right;
    left: auto;
    right: 20px;
  }

  .ccv {
    height: 36px;
    background: #fff;
    width: 91%;
    border-radius: 5px;
    top: 80px;
    @media (min-width: 768px) {
      top: 110px;
    }
    left: 0;
    right: 0;
    position: absolute;
    margin: 0 auto;
    color: #000;
    text-align: right;
    padding: 10px;

    label {
      margin: -25px 0 14px;
      color: #fff;
    }
  }

}