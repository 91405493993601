
.cart-sticky {
  display: none;
}
@media (min-width: 768px){
  .body-container-cart {
    margin-right:344px;
  }
  .body-container {
    //margin-right:344px;
  }
  .cart-container {
    max-width:1024px;
    margin-right: auto;
    margin-left: auto;
  }
  .cart-sticky {
    display: block;
    position: fixed;
    //top:54px;
    //padding-top:0px;
    top:0px;
    padding-top:54px;
    //padding-top:58px;
    right:0px;
    bottom:0px;
    width:344px;
    background: #fff;
    z-index:1;
    border-left:1px solid $color-border;
    .cart-sticky-header{
      text-align: center;
      h3 {
        padding:15px;
        font-size: 16px;
      }
    }
    .cart-sticky-body {

      .cart-items-quantity {
        width:80px;
        .form-control {
          //padding: 10px;
          font-size: 12px;
        }
      }
      .cart-items-name {
        h4 {
          font-size: 15px;
        }
        h5 {
          font-size: 13px;
          margin-bottom: 3px;
        }
        ul {
          margin-bottom: 6px;
          li {
            font-size: 12px;
            ul {
              margin-left: 10px;
            }
          }
        }
      }
      .scoller {
        //top: 110px;
        top: 104px;
        border-bottom: 0;
        bottom: 0px;
        padding: 0;
        position: absolute;
        border-bottom: 1px solid #e9e9e9;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
      }
      .scoller-content {
        overflow: auto;
        display: flex;
        flex-direction: column;
      }
      .scoller-btn {
        padding:10px;
      }
    }
    .cart-sticky-summary, .cart-sticky-items {
      border-bottom: 1px solid $color-border;
    }
    .cart-sticky-summary {
      padding: $padding-lg;
    }
    .cart-sticky-items {
    }
  }
}