footer.footer {
  background-color: $color-text-primary;
  margin-top: 35px;
  padding: 40px 0;
  text-align: left;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  font-size: 14px;
  color: #fff;
  padding-bottom: 100px;
  .footer-links {
    text-align: center;
    margin-left:0px;
    //display: inline;
    white-space: nowrap;
    padding-left: 0px;
    list-style: none;
    li {
      display: inline-block;
      //margin-right:5px;
      padding-right:3px;
      margin-left:3px;
      //padding-left:3px;
      &:after {
        content: " \00b7";
        padding-left:2px;
      }
    }
    > li:last-child:after  {
      content: none;
    }
  }

  .footer-social {
    text-align: center;
    margin-left:0px;
    //display: inline;
    white-space: nowrap;
    padding-left: 0px;
    list-style: none;
    li {
      display: inline-block;
      padding-right:5px;
      margin-left:5px;
      svg {
        fill: #fff;
      }
    }
  }

   .logo {
    height: 50px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
     img {
       max-height: 75%;
     }
  }
  .footer__use {
    text-align: center;
    font-size: 14px;
    img {
      height: 14px;
      vertical-align: middle;
    }
    a {
      text-decoration: none;
      padding-left: 5px;
    }
  }

  a {
    text-decoration: none;
    color: #fff;
    vertical-align: middle;

    &:hover {
      color: $color-accent-light;
    }
  }
}
@media (min-width: 768px){
  footer.footer {
    padding-bottom: 40px;
  }
}