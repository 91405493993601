

.parent-top-nav {
  //z-index:1;
  z-index:1001;
}

.container-menu-picker {
  margin-top: 15px;
  margin-bottom: 15px;
  .nav-link-header {
    display: none;
  }
  .nav-link {
    text-align: center;
    .nav-link-error {
      color: $color-red;
      display: block;
    }
  }
  .badge {
    font-size: 100%;
    padding: $padding-default;
  }
  ul {
    flex-direction: column;
    margin-bottom: 0px;
    //font-size:75%;
  }
  ul.list-inline-hours {
    margin-left:$padding-sm;
    font-size: 75%;
    font-weight: 300;
    li {
      margin-right: 0px;
      padding-right: 0px;
    }
  }
  .menu-item-content {
    background-color: $color-accent-light;
    color: #0d6efd;
    //align-items: center;
    text-align: left;
    font-weight: 500;
    height:40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .menu-item-header {
      display: flex;
      align-items: center;
    }
    .menu-item-error {

      display: flex;
      flex-direction: column;
      //align-items: center;
      color: $color-red;
      font-weight: 300;
      //display: block;
    }
  }
  .container-menu-picker-menus {
    .nav {
      display: none;
    }
  }
  .btn-choose-menu {
    background-color: $color-accent-light;
    color: #0d6efd;
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 500;
    //height: 40px;
    //> div {
    //  display: flex;
    //  align-items: center;
    //}
    //small {
    //  //margin-left: 10px;
    //  font-weight: 300;
    //  &.error {
    //    color: $color-red;
    //    display: block;
    //  }
    //}
    &:after {
      display: inline-block;
      margin-left: auto;
      //margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
  .dropdown {
    width: 100%;
    .btn {
      text-align: left;
      width: 100%;
      background-color: $color-accent-light;
      color: #0d6efd;
      display: flex;
    }
  }
}
@media (min-width: 768px){
  .container-menu-picker {

    .container-menu-picker-menus {
      .nav {
        display: flex;
      }
      .btn-choose-menu {
        display: none;
      }
    }
    .nav-link.active {
      background-color: $color-accent-light;
    }
    .nav-link-header {
      display: block;
    }
    ul {
      flex-direction: inherit;
    }
  }
}


.nav-tabs-top {
  overflow-x: scroll;
  overflow-y:hidden;
  flex-wrap: nowrap;
  position: relative;
  z-index: 980;
  background-color: #fff;
  box-shadow: $color-accent-light 0px -3px 0px inset;


  &::-webkit-scrollbar {
    height: 0px;
  }
  .nav-item {
    //margin-top: -3px;
    //margin-bottom: -3px;
  }
  .nav-link {
    border: 1px solid transparent;
    border-radius: 0px;
    white-space: nowrap !important;
    padding: 1rem 1rem;
    color: $color-text-primary;
  }
  .nav-item.active {
    .nav-link {
      color: $color-text-primary;
      //background-color: #fff;
      border-bottom-color: $color-accent;
      //border-top-width: 3px;
      border-bottom-width: 3px;
    }
  }
}

@media (max-width: 640px){
}
.scrollspy-section {
  padding-top:50px;
}
.scrollspy {
  margin-bottom: 100px;
}


