.summary-loading {
  //height:300px;
  position: relative;
}
.summary-container {
  .summar-header {
    padding:10px;
    h5  {
      font-size: 17px;
      font-weight: 500;
    }
  }
  .cart-response-restaurant {
    padding-top:50px;
    padding-bottom: 50px;
    text-align: center;
    h3 {
      font-weight: 300;
      font-size: 26px;
      margin-bottom: 20px;
    }
    .cart-response-info {
      margin-bottom: 10px;
      strong {
        display: block;
        font-size: 30px;
        font-weight: 500;
        color: #2A2A2A;
      }
    }
  }
  .summary-waiting {
    text-align: center;
    //margin-bottom: 20px;
    h3 {
      font-weight: 300;
      font-size: 26px;
      margin-bottom: 20px;
    }
  }
  .card-summary {
    background-color: #F4F4F4;
  }
}
.App .summary-container {
  .cart .card {
    border-bottom-width: 10px !important;
    margin: 0px;
  }
}

@media (min-width: 768px){
  .summary-loading {
    height:250px;
  }
  .summary-container {
    max-width:1024px;
    margin-right: auto;
    margin-left: auto;
  }
}