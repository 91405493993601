$color-font-custom: #cfcfcf;
$color-bg-custom: #272727;
$color-bg-light-custom: #555;
$color-bg-black: #000;

body.dark-mode {
  color: $color-font-custom;
}
.dark-mode {
  .App {
    background: $color-bg-black;
  }
  .table {
    color: $color-font-custom;
  }
  .menu-item-container .card {
    background: transparent;
  }
  .navbar, .cart-sticky {
    background: $color-bg-black;
  }
  .navbar .navbar-brand {
    color: $color-font-custom;
  }
  .navbar .navbar-nav .nav-link {
    color: $color-font-custom;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar .content {
    color: $color-font-custom;
  }
  .menu .menu-items .menu-item {
    color: $color-font-custom;
  }
  .menu .menu-items-list .product-item {
    border-color: $color-bg-light-custom;
  }
  .cart-sticky {
    border-left-color: $color-bg-light-custom;
  }
  .cart-sticky .cart-sticky-summary, .cart-sticky .cart-sticky-items {
    border-bottom-color: $color-bg-light-custom;
  }
  .cart-sticky .cart-sticky-body .scoller {
    border-bottom: $color-bg-light-custom;
  }
  .product-item .product-item-description {
    border-bottom-color: $color-bg-light-custom;
  }
  .menu .menu-items .card {
    border-color: $color-bg-light-custom;
    background-color: $color-bg-custom;
  }
  .card-header {
    background-color: $color-bg-custom;
  }
  .btn-default {
    border-color: $color-bg-light-custom;
    //color: $color-bg-light-custom;
    color: $color-font-custom;
  }
  .nav-tabs-top {
    background-color: $color-bg-black;
  }
  .nav-tabs-top .nav-item.active .nav-link {
    //background-color: $color-bg-custom;
    color: $color-font-custom;
  }
  .nav-tabs-top .nav-link {
    color: $color-font-custom;
  }
  .container-menu-picker .nav-link.active {
    background-color: $color-bg-custom;
  }
  .btn-primary-light {
    background-color: $color-bg-custom;
    border-color: $color-bg-custom;
  }
  .nav-tabs-top {
    box-shadow: $color-bg-light-custom 0px -3px 0px inset;
  }

  .table th, .table td {
    border-top-color: $color-bg-light-custom;
  }
  .form-control {
    background-color: $color-bg-black;
    border-color: $color-bg-light-custom;
    color: $color-font-custom;
    &:focus {
      background-color: inherit;
      color: $color-font-custom;
    }
  }
  .form-group input.form-control, .form-group select.form-control {
    background-color: $color-bg-black;
    border-color: $color-bg-light-custom;
    color: $color-font-custom;
  }
  .list-group-item {
    background-color: transparent;
    border-color: $color-bg-light-custom;
    color: $color-font-custom;
    &:hover, &:focus {
      background-color: $color-bg-custom;
    }
  }
  .header-strike span {
    background-color: $color-bg-black;
  }
  .header-strike:before {
    border-top-color: $color-bg-light-custom;
  }
  .cover .cover-content .cover-content-center-content {
    background-color: $color-bg-custom;
    border-color: $color-bg-light-custom;
  }
  .modal-content {
    background-color: $color-bg-custom;
    border-color: $color-bg-light-custom;
  }
  .modal-header {
    border-bottom-color: $color-bg-light-custom;
  }
  .modal-full .product-item-modifiers {
    border-bottom-color: $color-bg-light-custom;
  }
  .modal-full .product-item-footer, .modal-full .modal-footer {
    background-color: $color-bg-custom;
  }
  .modal-full .modal-body {
    background-color: $color-bg-custom;
  }


  //WIDGET

  .widget .footer {
    background: transparent;
  }
  .widget .sticky-fixed {
    background: $color-bg-black;
  }
  .widget .footer a {
    color: $color-font-custom;
  }
  .widget .menu .menu-items .card {
    background-color: transparent;
  }
  //body, .widget .sticky-fixed-menu {
  //  background: transparent;
  //}
  //.widget .sticky-fixed {
  //  background: transparent;
  //}

  $color-custom: #22ace9;
  $color-custom-rgb: "197,1,2";
  @import "color";
}