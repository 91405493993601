@import "buttons";
body.design-v2 {
  font-family: Roboto, Rubik, sans-serif;
}
.design-v2 {
  .input-group-quantity {
    align-items: stretch;
    .input-group-prepend, .input-group-append {
      button {
        //background-color: #E8F1FF;
        //border-color: #E8F1FF;
        font-weight: 700;
        font-size: 24px;
      }
    }
    .input-group-prepend button {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .input-group-append button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .input-group-text {
      font-weight: 700;
      font-size: 18px;
      border: 1px solid    #E8F1FF;
      display: flex;
      justify-content: center;
      align-items: center;
      //padding-top: 2px;
      //padding-bottom: 2px;
      padding: 2px 10px;
    }
    &.input-group-sm {
      .input-group-prepend, .input-group-append {
        button {
          font-size: 20px;
        }
      }
      .input-group-text {
        font-size: 14px;
        font-weight: 500;
        padding: 2px 10px;
      }
      button {
        width: 30px;
        height:30px;
        padding:0px;
      }
    }
  }

  .btn {
    border-radius: 8px;
  }
  .text-primary {
    path, svg {
      fill: $color-accent;
    }
  }
}

@import "./product";