.navbar {
  //position: relative;
  padding: .5rem 1rem;
  z-index: 2;
  background-color: #fff;
  .navbar-brand {
    padding-top: 3px;
    padding-bottom: 3px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    img {
      height:30px;
      //margin-right:10px;
    }
  }
  .navbar-actions-container-desktop {
    display: none;
  }
  .navbar-cart-icon {
    svg {
      fill: $color-accent;
    }
  }
  form {
    width: 100%;
    > div {
      width:100%;
    }
  }
  .navbar-brand-container {
    display: flex;
    width: 100%;
    flex-flow: wrap;
  }
  .navbar-actions-container {
    width: 100%;
  }
  .btn, input, .navbar-toggler {
    font-size:13px;
  }
  input {
    border-radius: 0px;
    height:38px;
  }
  .navbar-options {
    display: flex;
    margin-left: auto;
    //flex: 1 1 auto;
    .search-type {
      flex: 1 1 auto;
      button {
        width:100%;
      }
    }
    .btn {
      height: 100%;
      padding-left:7px;
      padding-right:7px;
    }
    .btn-pick-date {
      flex: 1 1 auto;
      display: inline-block;
      min-width: 70px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
  .btn-user {
       .dropdown-toggle:after {
          display: none;
       }
  }
  .navbar-toggler {
    border-radius: 0px;
  }
  .search-input {
    .dropdown-footer {
      height:30px;
    }
  }
  .navbar-actions {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    > .btn {
      margin-left: auto;

      &:after {
        display: inline-block;
        //margin-left: auto;
        margin-left: 10px;
        //margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }
    }
  }
  .navbar-form {
    padding-top:15px;
    border-top:1px solid $color-grey-super-light;
    margin-top:10px;
  }
  button.navbar-actions{
    border: none;
    background: transparent;
    text-align: left;
    padding:0px;
  }
  .languages {
    text-align: center;
    width: 100%;
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
  .navbar-languages {
    .btn {
      color: #323232;
      padding-right:0px;
      svg {
        margin-right: 5px;
      }
    }
    .dropdown-item svg {
      margin-right: 7px;
    }
  }
  //flex: 1 1 auto!important;
}
@media (min-width: 768px){
  .navbar {

    //.navbar-brand-container {
    //  width: auto;
    //}
    .navbar-brand-container {
      width:100%;
      flex-flow: inherit;
      .navbar-menus {
        order:2;
      }
      .navbar-actions-container {
        display: none;
        order:1;
      }
      .navbar-options {
        order: 3;
      }
    }
    .navbar-actions-container-desktop {
      display: flex;
    }
    .navbar-actions {
      margin: 0px;
      .content {
        order: 2;
      }
      .btn {
        order: 1;
        margin-right:10px;
      }
    }
    .navbar-brand {
      max-width: inherit;
    }
    .navbar-options {
      flex:none;
    }
    .navbar-nav {
      //margin-right: 20px;
      margin-left: auto;
      .nav-link {
        color: $color-text-primary;
        &:hover {
          color: $color-accent;
        }
      }
    }

    form {
      width: auto;
      > div {
        width:auto;
      }
    }
    //.btn {
    //  margin-right: $padding-default;
    //}
    .search-type, .btn-pick-date {
      margin-right: $padding-default;
    }
    .btn, input, .navbar-toggler {
      font-size:15px;
    }
    .search-input {
      input {
        width: 400px;
      }
    }
    .navbar-actions {
      width: auto;
    }
    .languages {
      text-align: left;
      width: auto;
    }
  }
}
.navbar-native {
  display: none;
}
.navbar-cart {
  .navbar-actions {
    margin-top:0px;
    margin-bottom: 0px;
  }
}