.card-advert {
  background-color: #F1FCF2;
  color: $color-text-primary;
  font-size: 16px;
  font-weight: 500;
  .card-body {
    display: flex;
  }
  svg {
    fill: #28A745;
    margin-right: 20px;
    width:24px;
    height:24px;
    path {
      width:24px;
      height:24px;
      fill: #28A745;
    }
  }
}