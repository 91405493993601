.design-v2 {
  .price {
    color: $color-accent;
  }
  .modal-product-full {
    .modal-body {
      background-color: transparent;
      padding:0px;
      .product-item {
        padding-top:10px;
      }
    }
    .modal-content{
      display: flex;
    }

    .product-item-footer, .modal-footer {
      background-color: #fff;
      position: sticky;
      z-index: 1000;
      opacity: 1;
      bottom: 0px;
      left:0px;
      right:0px;
    }
    .modal-product-full-right {
      background-color: #F2F4F6;
    }
    .modal-header {
      padding-left:0px;
      padding-right: 0px;
      position: sticky;
      top:0px;
      background-color: #F2F4F6;
      z-index: 1000;
      &.back-icon {
        margin-right: 10px;
        
      }
    }
    .modal-header-back {
      justify-content: left;
    }
    .product-item-description {
      padding-left:0px;
      padding-right: 0px;
      padding-bottom: 0px;
      border:0px;
    }
    .product-item-img {
      position: sticky;
      padding:10px;
      z-index: 1000;
      opacity: 1;
      top: 0px;
      width:100%;
      img {
        width:100%;
        object-fit: cover;
        //height: 80vw;
      }
    }
    .product-item {
      legend {
        font-size: 16px;
        font-weight: 700;
      }
      .product-modifier-option .btn-pick {
        margin-bottom: 10px;
      }
      .product-item-modifiers {
        padding-left: 0px;
        padding-right: 0px;
        .list-inline {
          margin-bottom: 0.5rem;
        }
        .list-inline > li {
          border-right: 1px solid $color-accent;
          line-height: 1;
          &:last-child {
            border-right: none;
          }
          &:after {
            content: none;
            padding-left:0px;
          }
        }
      }
      .product-item-modifiers-errors {
        margin-bottom: 0.5rem;
        color: $color-red;
      }
      .product-modifier-option-pick {
        margin-bottom: 10px;
        label {
          display: flex; 
          justify-content: center;
          align-items: center;
          background-color: #fff;
          border:1px solid $color-border;
          border-radius: 8px;
          line-height: 1.3;
          padding:8px;
          cursor: pointer;
          flex-direction: column;
          text-align: center;
          font-size: 14px;
          width:100%;
          height: 100%;
          font-weight: 500;
          span {
            font-size: 14px;
            font-weight: 300;
            display: block;
            text-align: center;
            margin-left: 0px !important;
          }
        }
        input {
          display: none;
        }
        input:checked + label {
          color: #fff;
          background-color: $color-accent;
          span {
            color: #fff;
          }
        }
      }

      .product-item-footer {
        //margin-left: -15px;
        //margin-right: -15px;
        padding-top:15px;
        padding-bottom:15px;
      }
      .btn-submit {
        height:56px;
        padding:4px;
        font-weight: 500;
        .btn-cart-price {
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
    .product-option-with-image {
      background-color: #fff;
      padding:10px;
      border: 1px solid $color-border;
      border-radius: 8px;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;
      &.error {
        border-color: $color-red;
      }
      .check-circle {
        display: none;
        position: absolute;
        left:5px;
        top:5px;
      }
      .product-option-multilevel {
        margin-top:12px;
        p {
          margin-bottom: 6px;
        }
        &.d-flex, .d-flex {
          .btn {
            margin-right: 6px;
          }
          .btn:last-child {
            margin-right: 0px;
          }
          .last {
            margin-left: auto;
          }
        }
      }
      .product-option-errors {
        margin-top:10px;
      }
      &.active {
          border-color: $color-accent;
        .check-circle {
          display: block;
          z-index: 1;
        }
      }
      .card-img {
        width: 70px;
        height: 70px;
        margin-right: 10px;
        flex-shrink: 0;
        margin-bottom: 0px;
        img {
          max-width: 100%;
        }
        svg,path {
          margin:10px;
          width: 50px;
          height: 50px;
        }
      }
      h4 {
        font-size: 14px;
      }
      .product-option-body {
        flex: 1 1;
        display: flex;
        position: relative;
      }
      .product-option-content {
        position: relative;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        display: flex;
        p {
          font-size:14px;
          line-height: 18px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* Limit to two lines */
          overflow: hidden;
          text-overflow: ellipsis; /* Add ellipsis for overflowed text */
          white-space: normal; /* Wrap text within the specified number of lines */
        }
      }
      .product-option-actions {
        //position: absolute;
        //bottom:0px;
        //right: 0px;
        //left:0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price {
          font-size: 12px;
          font-weight: 500;
          small {
            color: $color-text-primary-light;
          }
        }
      }
      .product-option-quantity {
        margin-left: auto;
        //padding-right:10px;
        .input-group {
          width:80px;
        }
        input {
          padding-left:3px;
          padding-right:3px;
          border-color: #E8F1FF;
        }
      }
    }
  }

  //.input-group-new {
  //  .input-group {
  //    width:80px;
  //  }
  //  .input-group-text {
  //    display: block;
  //    width: 1%;
  //    flex: 1 1 auto;
  //    border-color: #E8F1FF;
  //    background-color: #fff;
  //  }
  //}

  @media (min-width: 768px) {
    .modal-product-full {
      width: 800px;
      .modal-content{
        width: 800px;
      }
      .modal-product-full-right{
        flex-direction: column;
        display: flex;
      }
      .product-item {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex:1;
        .product-item-content {
          flex:1;
        }
      }
      
    }
  }
}
