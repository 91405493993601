.widget {
  position: relative;
  .cover {
    display: none;
  }
  .navbar {
    position: fixed;
    top:0px;
    width: 100%;
  }
  .menu {
    //margin-top:170px;
    padding-top: 100px;
  }
  .sticky-fixed {
    background-color: #fff;
  }
  .fixed-bottom {
  }
  .sticky-fixed-categories {
    position: fixed;
    top:0px;
    //margin-top:110px;
    margin-top:50px;
    @media (max-width: 768px){
      width: 100%;
    }
  }
  .sticky-fixed-cart {
    position: fixed;
    top:0px;
  }
  .sticky-fixed-menu {
    position: fixed;
    top:0px;
    margin: 0px;
    left:0px;
    right:0px;
    //margin-top: 58px;
    //padding: 15px 0px;
    z-index: 1002;

  }

  .navbar, .sticky-fixed-menu, .sticky-fixed-categories {
    @media(min-width: 768px){
      left:0px;
      right:344px;
      width:auto;
    }
  }
  .cart-container {
    //margin-left:0px;
    //margin-rigth:0px;
  }
  .sticky-fixed-cart {
    padding-top:0px;
    &.cart-sticky .cart-sticky-body .scoller {
      top:50px;
    }
  }
  //&.modal-open {
  //  overflow: scroll !important;
  //}
  //.modal-full .modal-content {
  //  top: 0px;
  //  border-bottom: 0;
  //  bottom: 50px;
  //  padding: 0;
  //  position: absolute;
  //  border-bottom: 1px solid #e9e9e9;
  //  left: 0;
  //  right: 0;
  //  display: flex;
  //  flex-direction: column;
  //  .modal-body {
  //    overflow: auto;
  //    display: flex;
  //    flex-direction: column;
  //  }
  //  .product-item-img {
  //    overflow: auto;
  //  }
  //  .modal-footer{
  //    background-color: #fff;
  //  }
  //}
  .container-menu-picker ul {
    flex-direction: inherit;
    padding:0px;
    .nav-link {
      padding: 0.4rem 1rem;
      min-height:50px;
    }
    .nav-link-header {
      line-height: 38px;
    }
  }
  @media (max-width: 768px){
    .container-menu-picker ul {
      .nav-link-header {
        display: none;
      }
    }
    .modal-full {
      min-height: auto;
      .close {
        position: absolute;
      }
    }
    .modal-full .modal-footer {
      position: relative;
    }
  }
  .footer {
    a {
      color:$color-text-primary;
    }
    background: #fff;
    color:$color-text-primary;
  }
  .menu {
    .languages {
      display: block;
    }
  }
}
//.modal-dialog {
//  overflow: scroll;
//  height: 400px;
//}
//.modal-content {
//  height: 1000px;
//}
@import "widget_native_mobile";
