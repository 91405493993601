.widget-native-mobile {
  .cover {
    display: none;
  }
  //.navbar {
  //  display: none;
  //}
  .navbar-native {
    display: inherit;
    .navbar-actions {
      margin:0px;
      width:auto;
    }
  }
}