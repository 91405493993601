//.modal-open {
//  height: 100%;
//}
.design-old{

  .modal-full.modal-product {
    .alert {

    }
    .modal-body {
      padding:0px;
    }
    .form-check-input {
      position: relative;
    }

    .product-item-footer, .modal-footer {


      background-color: #fff;
      position: sticky;
      z-index: 1000;
      opacity: 1;
      bottom: 0px;
      left:0px;
      right:0px;

    }
    .product-item-img {
      width:100%;
      img {
        width:100%;
        object-fit: cover;
        height: 80vw;
      }
    }
  }

  @media (min-width: 992px) {
    .modal-full.modal-product{

      .product-item-img {
        img {
          height: 400px;
        }
      }
    }
  }
  @media (max-width: 768px){
    .product-item-footer, .modal-footer {
      //position: fixed;
      //border-top:1px solid $color-border;
      .btn-group {
        display: block;
        .input-group {
          margin-bottom: 10px;
        }
        .btn {

        }
      }
    }
  }


  .modal-product{

    .product-item {
      //padding:1rem;
      img {
        width:100%;
      }
      h2 {

      }

      .product-item-variant + .product-item-modifiers {
        border-top: 1px solid $color-border;
      }
      .product-item-modifiers {
        //margin-bottom: $padding-lg;
        padding:1rem;
      }

      .product-item-variant {
        &.product-item-modifiers {
          margin-bottom: 0px;
          padding-bottom: 0px;
        }
      }
      .product-item-header {
        position: fixed;
        top:0px;
      }
      .product-modifier-option {
        margin-bottom: 5px;
        align-items: center !important;
        display: flex !important;
        .input-group {
          width:auto;
        }
        .product-modifier-option-price {
          margin-left: auto !important;
          text-align: right;
        }
      }
      .product-item-description {
        border-bottom: 1px solid $color-border;
        margin-bottom: $padding-lg;
        //padding-bottom: $padding-lg;
        //padding-left: 1rem;
        //padding-right: 1rem;
        //padding-top: 1rem;
        padding: $padding-lg;
      }
      .product-item-footer {
        padding:1rem;
        border-top: 1px solid $color-border;
        .prices {
          .price-discount {
            margin-right: 5px;
            color: #D9534F;
            text-decoration: line-through;
            font-weight: 300;
          }
        }
      }
    }
  }
}