.cover2 {
  //min-width: 1024px;
  //max-width: 1920px;
  position: relative;
  margin-bottom: 15px;
  .cover-content {
    min-height: 160px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    display: flex;
    > div:first-child {
      display: flex;
      flex-grow: 1;
    }
    > div:last-child{
      flex-grow: 1;
    }
    .cover-content-center-info {
      cursor: pointer;
      color: #fff;
      //font-size:40px;
      position: relative;
      //width: 100%;
      text-align:center;
      padding: $padding-default;
      line-height: 1;

      background-color: $color-red;
      font-size:14px;
      margin: 0px 20px;
    }
    .cover-content-center{
      height: 165px;
      display: flex;
      align-items: center;
    }
    .cover-content-center-bg-close {
      position: absolute;
      bottom: 0px;
      left:0px;
      right:0px;
      top:0px;
      background:rgba(0, 0, 0, 0.65);
    }
    .cover-content-center-content{
      color:#fff;
      position: relative;
      //background: #fff;
      //border: 1px solid $color-border;
      //border-radius: 4px;
      //margin: 15px 20px;
      width:100%;
      margin:0px;
      padding:0.5rem;
      //display: flex;
      //padding:$padding-lg;
      h1 {
        color: #fff;
        font-size: 18px;
        margin-bottom: 8px;
        small {
          font-size: 13px;
          display: block;
        }
      }
      .cover-info {
        margin-bottom: 8px;
      }
      .cover-time {
        display: flex;
        color: #fff;
        ul {
          margin-bottom: 0px;
        }
        margin-bottom: 0px;
        .badge {
          //margin-left:$padding-default;
        }
      }
      .cover-tags {
        margin-bottom: 15px;
      }
      .cover-address {
        margin-bottom: 0px;
        //margin-bottom: 8px;
      }
      button.badge {
        border: 1px solid transparent;
      }
      .btn-more {
        //border: 1px solid #fff;
        //border-radius: 2px;
        color:#fff;
        background: rgba(0, 0, 0, 0.6);
      }
      .badge-default {
        margin-right:10px;
        background: $color-blue-light;
        color: $color-text-secondary;
        font-weight: 300;
      }
      //ul.types {
      //  margin-left:0px;
      //  display: inline;
      //  white-space: nowrap;
      //  padding-left: 0px;
      //  list-style: none;
      //  li {
      //    display: inline-block;
      //    margin-right:5px;
      //    padding-right:3px;
      //    &:after {
      //      content: " \00b7";
      //      padding-left:2px;
      //    }
      //  }
      //  > li:last-child:after  {
      //     content: none;
      //  }
      //}
      > div {

      }
    }
  }
  .cover-img {
    bottom: 0px;
    top:0px;
    left:0px;
    right:0px;
    position: absolute;
    > div {
      height:100%;
      width:100%;
      overflow: hidden;
      left:0px;
      right:0px;
      position: absolute;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (min-width: 768px){

  .cover-wrapper {
    margin-right: 344px;
  }
  .cover2 {
    //margin-right: 344px;
    .cover-content {
      //margin-right: 344px;
      align-items: start;
      .cover-content-center {
        width: 100%;
      }

      .cover-content-center-info {
          font-size:40px;
          width: 100%;
          background-color: transparent;
          line-height: 1.3;
          span {
            display: block;
            font-size: 20px;
          }
      }
    }
  }
}

@media (min-width: 992px){
  .cover2 {
    .cover-content {
      align-items: start;

      .cover-content-center{
        align-items: flex-end;
        flex-direction: row;
        display: flex;
        width:100%;
        height:auto;
      }
      .cover-content-center-content{
        margin-top: 100px;
        padding: 25px 0.5rem;
        //margin:0px;
        //order: -1;
        //padding:40px;
        //min-width: 300px;
        min-width: 400px;
        max-width: 50%;
        //margin: 50px 20px;
      }
    }
  }
}

@media (min-width: 768px){
  .cover2 {
    .cover-img {
      //right: 344px;
    }
  }
}
@media (min-width: 1200px){
  .cover {
    .cover-content {
      .cover-content-center-content{
        min-width: 450px;
      }
    }
  }

}
