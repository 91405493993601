@import "fontsRoboto";
body {
  //font-family: Open Sans, Roboto, sans-serif;
  //font-family: Rubik, Roboto, sans-serif;
  font-family: Rubik, Roboto, sans-serif;
  font-weight: 300;
  font-size:14px;
  color: $color-text-primary;
  background-color: transparent;
  //color: $color-text-primary;
}
.App {
  background-color: #fff;
}
h1 {
  font-size: 41px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 19px;
}
h5 {
  font-size: 17px;
}
a {
  color: $color-accent;
}


.header-strike {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.header-strike:before {
  content: "";
  display: block;
  border-top: solid 1px $color-border;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.header-strike span {
  background: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 5;
}

.badge-accent-light {
  background: $color-accent-light;
}

.alerts {
  position: fixed;
  left: 0;
  right: 0;
  top:0px;
  width: 600px;
  max-width:100%;
  margin: 0 auto;
  margin-top: 30px;
  z-index: 1051;
  .alert {
    margin-left:15px;
    margin-right:15px;
    margin-bottom: 10px;
  }
}

.icon{
  width: 24px;
  height: 24px;
}
b, strong {
  font-weight: 500;
}
@media (max-width: 767px){
  .container {
      max-width:100%;
  }
}

.cookies {
  position: fixed;
  bottom:0px;
  left:0px;
  right:0px;
  text-align: center;
  background-color: rgba(0,0,0,0.7);
  z-index:1035;
  padding:15px;
  color:#fff;
  .btn {
    margin-left: 20px;
  }
}
//.back {
//  m
//}

.languages {
  text-align: center;
  width: 100%;
  @media (min-width: 768px){
    //text-align: left;
    //width: auto;
  }
  &.languages-xl {
    svg {
      height:50px;
      width:50px;
    }
  }
}

.modal {
  .languages{
    margin-bottom: 20px;
  }
}

.horizontal-row .horizontal-text:before {
  right: 0;
}

.horizontal-row {
  margin-top: 15px;
  margin-bottom: 15px;
}

.horizontal-row .horizontal-text {
  width: 100%;
  text-align: center;
  background-color: #fff;
  position: relative;
  color: #ababab;
  font-size: 14px;
  z-index: 1;
  overflow: hidden;

  &:after, &:before {
    width: 47%;
    top: 50%;
    height: 1px;
    background-color: rgb(208, 208, 208);
    content: " ";
    position: absolute;
    overflow: hidden;
  }

  &:after {
    left: 0;
  }
}



.horizontal-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0; /* Spacing around the divider */
  position: relative;
}

.horizontal-divider::before,
.horizontal-divider::after {
  content: '';
  flex: 1;
  border-top: 1px solid #ccc; /* Line style */
  margin: 0 0px; /* Spacing around the text */
}

.horizontal-divider span {
  white-space: nowrap; /* Prevent text from wrapping */
  padding: 0 10px; /* Add spacing around the text */
  background: #fff; /* Match background color */
  color: #333; /* Text color */
}