body > .modal-backdrop {
  z-index: 1055;
}
.modal-full {
  max-width: 700px;

  .modal-content {
    width: 700px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px 16px;
    z-index: 1000;
    font-size: 2rem;
    color: #282c34;
    opacity: 1;
    box-shadow: none;
    border-radius: 50%;
    background-color: #fff;
    line-height: 1.1;

    &:hover {
      background-color: $color-accent;
      color: #fff;
      opacity: 1;
    }

    //margin: 0px;
  }
}


@media (max-width: 768px){

  html {
    //height: 100%;
    //overflow-y: scroll;
    //-webkit-overflow-scrolling: touch;
  }
  .modal-open  {
    //height: 100%;
    //overflow-y: scroll;
    //-webkit-overflow-scrolling: touch;
  }
  .modal-full {
    height:100%;
    margin:0px;
    border-radius: 0px;
    background: #fff;
    min-height: 100%;
    widht:100%;
    max-width: 100%;
    .modal-content {
      width:100%;
      border: none;
    }
    .modal-body {
      background-color: #fff;
    }
    .close {
      position: fixed;
      top:10px;
      right:10px;
      //padding:5px;
      z-index: 1000;
      //font-size:2rem;
      //color:#282c34;
      opacity: 1;
      //box-shadow: none;
      //margin: 0px;
    }
    .modal-content {
      border-radius: 0px;
      height: 100%;
      //overflow-x: hidden;
      //overflow-y: auto;
    }
    .modal-header-fixed {
      //z-index: 10050;
      position: fixed;
      top:0px;
      left:0px;
      right:0px;
    }
    .modal-header-sticky {
      .modal-title {
        display: none;
      }
      .modal-header {
        border-bottom: 0px;
      }
    }

  }
  .modal-footer--sticky {
    position: sticky;
    bottom: 0;
    background-color: inherit; /* [1] */
    z-index: 1055; /* [2] */
  }
}