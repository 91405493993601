$color-red: #D9534F;
$color-green: #28A745;
$color-blue: #1A86E0;
$color-blue-light: #E8F1FF;
$color-black: #000000;
$color-black-light: #202020;
$color-grey: #434343;
$color-grey-dark: #3b3b3b;
$color-grey-light: #9b9b9b;
$color-grey-super-light: #e2e2e2;
$color-white: #ffffff;
$color-white-light: #fafaf9;
$color-white-dark: #f4f4f3;

$color-text-primary: #2A2A2A;
$color-text-secondary: #535353;
$color-accent: $color-blue;
$color-accent-light: $color-blue-light;
$color-accent-dark: #1F286B;
$color-default: #36343B;
//$color-border: #dee2e6;
$color-border: #DCE6F6;

$color-primary-reverse: #fff;
$color-text-primary-light: #b8b8b8;

$padding-sm: 5px;
$padding-default: 10px;
$padding-lg: 15px;
$padding-xl: 20px;

