// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import "vanilla-cookieconsent/dist/cookieconsent.css";
@import "./css/variables";
@import "./css/basic";
@import "./css/common";

@import './css/buttons';
@import './css/modal';
@import './css/forms';
@import './css/navs';
@import './css/lists';
@import './css/badge';
@import './css/navbar';
@import './css/cover';
@import './css/cartSticky';
@import './css/advert';
@import './css/menu';
@import './css/page';
@import './css/cart';
@import './css/footer';
@import "./css/productOld";
@import "./css/promotions";
@import "./css/summary";
@import "./css/paymentCard";
@import "./css/voucher";
@import "./css/loading";
@import "./css/widget";
@import "./css/preview";
@import "./css/login";
@import "./css/new/new";
@import "css/dark/dark";
@import 'css/v3/coverv3';
@import 'css/v3/v3';
//@import 'css/custom/custom_tpizza';

.scrollspy1 {
  position: fixed;
  top:0px;
  left:0px;
  right:0px;
}
.section {
  height:300px;
}

