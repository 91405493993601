
.body .App .navbar-back a {
  color: #2A2A2A;

  path, svg {
    fill: #2A2A2A;
  }
}

.design-v3 {
  .btn-primary {
    color: #fff;
  }
  .btn {
    //color: #fff;
    border-radius: 6px;
  }

  .form-group input.form-control, .form-group select.form-control {
    border-radius: 6px;
  }


  .navbar-back {
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #DEE2E6;
    z-index: 1000;

    a {
      padding: 10px;
      text-decoration: none;
      color: #2A2A2A;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      path, svg {
        fill: #2A2A2A;
      }
    }
  }

  .cart {
    margin-top: 70px;
  }
  .summary-container .cart {
    margin-top: 0px;
  }

  .cart .card {
    border-bottom: 1px solid #C4C4C4 !important;
  }


  //carts
  .cart-items-body {
    padding: 10px;

    .cart-item:last-child {
      border-bottom: none;
    }
  }

  .cart-item {
    display: flex;
    border-bottom: 1px solid #C4C4C4;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .cart-item-img {
      width: 60px;
      margin-right: 16px;

      img {
        width: 60px;
        height: 60px;
      }
    }

    .cart-item-body {
      flex-grow: 1; /* Takes up the remaining space */
      h3 {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .cart-item-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; /* Or whatever width you need */
    }
    .cart-item-promotion {
      margin-top: 5px;
      text-align: right;
      font-size: 75%;
      width: 70%;
      margin-left: auto;
    }

    .cart-item-prices {
      font-size: 16px;
      font-weight: 500;
      color: $color-accent;
    }

    .cart-item-description {
      font-size: 12px;
      margin-bottom: 8px;
    }


    ul.list-inline {
      font-size: 12px;
    }

    ul.list-inline li:after {
      content: "";
      padding-left: 2px;
    }

    ul.list-inline li.list-inline-item-multi {
      display: flex;
      flex-wrap: wrap;

      h5 {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  .card {
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  .card-shipment {

  }

  .cart .card {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .cart .card-payment .list-group {
    h3 {
      font-size: 16px;
    }

    p {
      margin-bottom: 0px;
    }

    .list-group-item:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .list-group-item:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .summary-total-price {
    font-size:16px;
  }
  .summary-discount-price {
    color: $color-red;
  }

  //new

  .navbar-brand {
    align-items: center;
    display: flex;
  }
  .navbar .navbar-actions {
    margin-top: 10px;
  }

  .navbar-actions a {
    font-weight: 500;
  }

  .nav-tabs-top {
    box-shadow: inset 0 -3px 0 #E4E4E4 !important;
  }

  .nav-tabs-top .nav-item .nav-link {
    color: #36343B !important;
  }

  .nav-tabs-top .nav-item.active .nav-link {
    font-weight: 500;
    border-bottom-color: #36343B !important;
  }

  .scrollspy-section {
    padding-top: 20px;
  }

  .menu .menu-items .card .card-img {
    width: 110px;
    height: 110px;
    margin-right: 10px;
  }

  .menu .menu-items .card .card-img img {
    border-radius: 5px;
    width: 110px;
    height: 110px;
    object-fit: cover;
  }

  .menu .menu-items .card .card-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  .menu .menu-items .card .card-body .price {
    color: #D81111;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .container-menu-picker ul.list-inline-hours {
    margin-left: 10px;
    margin-bottom: -1px;
  }

  .container-menu-picker .btn-choose-menu {
    background-color: #F4F4F4;
    color: $color-accent;
  }

  .container-menu-picker .menu-item-content {
    background-color: #F4F4F4;
    color: $color-accent;
  }
  .container-menu-picker .nav-link.active {
    background-color: #F4F4F4;
    font-weight: 500;
  }

  .container-menu-picker .input-group-append .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 50px;
    font-size: 14px;
  }

  .container-menu-picker .input-group-lg > .form-control {
    font-size: 14px;
  }


  @media (min-width: 768px) {
    .menu .menu-items .card .card-body {
      padding: 10px;
    }
    .menu .menu-items .card {
      border-radius: 6px;
      align-items: center;
    }
    .menu .menu-items .card .card-img {
      margin:10px;
    }
    .menu .menu-items .card .card-body .card-text {
      font-size: 13px;
      height: 50px;
    }
    .menu .menu-items .card .card-body .price {
      margin-bottom: 0px;
    }
  }
}