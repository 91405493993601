.btn {
  border-radius: 0px;
  &.is-invalid {
    border-color: #dc3545!important;
  }
  h5 small {
    font-weight: 400;
  }
}
.btn-lg {
  padding:15px;
  font-size: 1rem;
}
.btn-sticky {
  padding:15px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  position: fixed;
}


.btn-secondary {
  background-color: #E8F1FF;
  border-color: #E8F1FF;
  color: #000;
}

.btn-transparent {
  color: $color-accent-light;
  background: transparent;
}
.btn-primary {
  color: #fff;
  border-color: $color-accent;
  background: $color-accent;
  &:hover, .active {
    color: #fff;
    //background: $color-accent-dark;
  }
}
.btn-primary-light, .btn-accent-light {
  color: $color-accent;
  border-color: $color-accent-light;
  background: $color-accent-light;
}

.btn-default {
  border-color: $color-border;
  color: $color-text-primary;
  background: transparent;
  &:hover, &.active {
    background: $color-default;
    color: $color-primary-reverse;
  }
  &.active {
    background: $color-default;
    color: $color-primary-reverse;
    &:focus, &:active, &:hover, &.focus {
      background: $color-default;
      color: $color-primary-reverse;
    }
  }
  &.focus {
    background: transparent;
  }
}

.btn-spinner {
  position: relative;
  .left {
    float:left;
  }
  .right {
    float:right;
  }
  :first-child {
    //margin-right: auto;
  }
  //justify-content: space-between;
  //display: flex;
  .spinner-border {
    float:left;
    width:1.5rem;
    height:1.5rem;
    display: none;
  }
  :last-child {

  }
  &.loading {
    .spinner-border {
      display: block;
    }
  }
}
.btn-submit {
  &:disabled, &[disabled] {
    .btn-submit-quantity {
      display: none;
    }
  }
  width:100%;
  //color: #fff;
  //border-color: $color-accent;
  padding:$padding-lg;
  //background: $color-accent;
  &:hover, .active {
    //color: #fff;
    //background: $color-accent-dark;
  }

  .btn-submit-quantity {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    border:1px solid #fff;
    height:24px;
    padding: 0px 7px;
  }
}

.btn-submit-container {
  left:10px;
  bottom:10px;
  right:10px;
}

.btn-submit-fixed-bottom {
  //margin-bottom: constant(safe-area-inset-bottom) !important;
  //margin-bottom: 20px !important;
}
//.btn-cart-submit {
//  &:disabled, &[disabled] {
//    .btn-submit-quantity {
//      display: none;
//    }
//  }
//  padding:15px;
//  //background-color: #282c34;
//  color: #fff;
//  &:hover, .active {
//    //color: $color-accent-light;
//  }
//  .btn-submit-quantity {
//    display: -webkit-box;
//    display: -moz-box;
//    display: -ms-flexbox;
//    display: -webkit-flex;
//    display: flex;
//
//    border:1px solid #fff;
//    height:24px;
//    padding: 0px 7px;
//  }
//  .btn-submit-price {
//
//  }
//}


.input-group-quantity {
  -webkit-box-pack: center;
  justify-content: center;
  margin:0px;
  margin-right:7px;
  .input-group-text {
    border:0px;
    width: 40px;
    padding-left:0px;
    padding-right:0px;
    display: block;
    text-align: center;
    background: transparent;

  }
}

.input-group-quantity {
  width:auto;
  flex-wrap: nowrap;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  //width:221px;
  .input-group-text {
    width: calc(1ch + 32px);
  }
  button {
    width: 30px;
    height:30px;
  }
}
.btn-group-submit {
  width:100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  .input-group-quantity {
    width:auto;
    flex-wrap: nowrap;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    //width:221px;
    .input-group-text {
      width: calc(1ch + 42px);
    }
    button {
      width: 56px;
      height:56px;
    }
  }
}
.btn-block  {
  display: block;
  width: 100%;
}
.btn-block+.btn-block {
  margin-top: .5rem;
}

.btn-pick-date {
  h5 {
    font-size:17px;
    small {
      display: block;
    }
  }
}

.btn-promotion {
  background-color: #F5F5F5;
  color: $color-text-primary;
  font-size: 14px;
  display: flex;
  align-items: center;
  width:100%;
  text-align: left;
  position: relative;
  padding: 11px;
  &.highlight {
    border: 1px solid #38B257;
    border-radius: 4px;
    background-color: #fff;
    //background-color: #38B257;
    //color: #fff;
    svg, svg path {
      fill: #38B257;
    }
  }
  .content {
    line-height: 16px;
  }
  .star {
    text-align: center;
    //flex: 0 0 32px;
    border-radius: 40px;
    width: 32px;
    height:32px;
    padding-top: 4px;
    padding-left: 1px;
    background-color: $color-green;
    margin-right: 20px;
  }
  > div:last-child {
    &.icon {
      margin-left: auto;
    }
  }
  &.loading {
    .icon svg {
      display: none;
    }
  }
  small {
    display: block;
  }
  .arrow {
    fill: #2A2A2A;
    margin-left: auto;
  }
  svg {
    fill: #fff;
  }
}