.menu  {
  .menu-advert {
    margin-top: 20px;
    img {
      max-width: 100%;
    }
  }
  .menu-category {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    h4 {
      font-size:21px;
      margin-bottom: 20px;
      svg {
        margin-right: 5px;
      }
    }
  }

  .card-category-list {
    padding:0px;
    border:0px;
    .card-header {
      margin-bottom: $padding-lg;
      h4 {
        margin-bottom: 0px;
      }
      p {
        margin-bottom: 0px;
      }
    }
    .card-img-top {
      height:150px;
      width:100%;
      margin-right:$padding-default;
      flex-shrink: 0;
      img {
        height:150px;
        width:100%;
        object-fit: cover;
      }
    }
  }
  .menu-items{
    .menu-item {
      color: $color-text-primary;
      text-decoration: none;
      &:hover .card, .card:hover {
        border-bottom:1px solid $color-accent;
        cursor: pointer;
      }
    }
    .card {
      &.disabled {
        opacity: .65;
      }
      border:0px;
      border-bottom: 1px solid $color-border;
      border-radius: 0px;
      flex: 1;
      display: flex;
      flex-direction: inherit;
      .card-body {
        position: relative;
        height:100%;
        //padding:0px;
        padding: $padding-default;
        //padding-left: 0px;
        //padding-right: 0px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        flex: 1;
        display: flex;
        h5 {
          font-size:16px;
          margin-bottom: 5px;
        }
        .card-text {
          font-size:13px;
          margin-bottom: 5px;
        }
        .btn-group{
          margin-bottom: 5px;
        }
        .prices {
          display: flex;
          .price {
            margin-right: 5px;
          }
        }
        .price-bottom {
          margin-top: auto;
        }
        .price {
          color: $color-accent;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 5px;
        }
        .price-discount {
          color: $color-red;
          text-decoration: line-through;
          font-weight: 300;
        }
        .tags {
          margin-bottom: 5px;
        }
      }

      .card-img {
        width:98px;
        height:98px;
        //height:100%;
        //width:100px;
        margin-right:$padding-default;
        flex-shrink: 0;
        margin-bottom: 5px;
        img {
          border-radius: 5px;
          width:98px;
          height:98px;
          //height:100%;
          //width:100px;
          object-fit: cover;
        }
      }
      .h5 {
        font-size:18px;
        font-weight: 300;
      }
      .card-text {
        font-size:14px;
        font-weight: 300;
      }
      margin-bottom: $padding-lg;
    }
  }

  .menu-items-list {
    .menu-item-container {
      width:100%;
      .menu-item, .card {
        margin-bottom: 0px;
      }
      margin-bottom: $padding-lg;
    }
    .product-item {
      border-bottom: 1px solid $color-border;
      border-left: 1px solid $color-border;
      border-right: 1px solid $color-border;
      //padding:$padding-lg;
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
  .menu-items-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -7px;
    margin-left: -7px;
    .menu-item {
      display: flex;
      -webkit-box-flex: 0;
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 7px;
      padding-left: 7px;
    }
  }

  .btn-search-icon {
    width:56px;
  }
}

@media (min-width: 768px){

  .menu-page {
    .btn-submit-bottom {
      display: none;
    }
  }
  .menu {
    .container {
      max-width: 1140px;
    }
    .menu-items{
      .menu-item {
        display: flex;
        flex:1 0 auto;


        &:hover .card, .card:hover {
          border:1px solid $color-accent;
        }
      }
      .card {
        width:100%;
        border: 1px solid $color-border;
        border-radius: 0px;
        .card-body {
          padding: $padding-default;
          .card-title {
            font-size:17px;
            margin-bottom: 0.5rem;
            //margin-bottom: 0.75rem;
          }
          .card-text {
            font-size:14px;
            height: 55px;
            line-height: 1.3;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            //margin-bottom: 0.75rem;
            //margin-bottom: 40px;
            //margin-bottom: 1rem;
            //margin-bottom: $padding-lg;
          }
          .btn-group {
            position: absolute;
            bottom:10px;
            right:10px;
            left:10px;
            //left:130px;
            margin-bottom: 0px;
          }
        }

        .card-img {
          width:120px;
          height:120px;
          margin-right:0px;
          img {
            width:120px;
            height:120px;
          }
        }
      }
    }

  }
}
.menu {
  .menu-items-grid {
    .menu-item {
      @media (min-width: 992px) {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media (min-width: 1400px){
        .card {

          .card-img {
            width:130px;
            height:130px;
            margin-right:0px;
            img {
              width:130px;
              height:130px;
            }
          }
        }
        //-ms-flex: 0 0 33.333333%;
        //flex: 0 0 33.333333%;
        //max-width: 33.333333%;
      }
    }
  }
  .languages {
    margin-bottom: 20px;
    margin-top:20px;
    display: none;
  }
}