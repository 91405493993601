.vouchers {
  margin-bottom: 20px;
  h4 {
    font-size: 21px;
    margin-bottom: 20px;
    svg {
      margin-right: 5px;
    }
  }
  .voucher-items {
      display: flex;
      flex-wrap: wrap;
      margin-right: -5px;
      margin-left: -5px;
  }
  .voucher-item {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
    cursor: pointer;
    .card {
      width: 100%;
      padding:10px;
      border-radius: 4px;
      .card-body {
        padding-left:0px;
        padding-right:0px;
      }
      .btn {
        color: #2A2A2A;
        text-align: left;
        border-top:$color-grey-super-light;
        padding-left: 0px;
      }
      &.disabled {
        background-color: rgba(245, 245, 245, 1);
        opacity: .6;
      }
    }
    .badge {
      position: absolute;
      top:20px;
      left:20px;
    }
  }
}

@media (min-width: 768px){
  .vouchers {
    .voucher-item {
      max-width: 33.33%;
    }

  }
}