.design-v2 {
  .btn-outline-danger {
    border-color: $color-border;
    path, svg {
      fill: $color-red;
    }
    &:hover {
      path, svg {
        fill: $color-white;
      }
    }
  }
  .btn-sm {
    svg,path {
      max-height:18px;
    }
  }
  .btn {
    &.disabled-view {
      //pointer-events: none;
      opacity: 0.65;
    }
  }
}